import {
  computed,
  inject,
  ref,
  unref,
  watch,
} from 'vue';
import {
  pipe,
  subscribe,
} from 'wonka';
import EventEmitter from 'eventemitter3';
import * as Sentry from '@sentry/vue';
import USER_QUERY from './user.graphql';
import FLAGS_QUERY from './flags.graphql';
import NOTICES_QUERY from './notices.graphql';
import LOGIN_MUTATION from './mutations/login.graphql';
import IMPERSONATE_MUTATION from './mutations/impersonate_start.graphql';
import STOP_IMPERSONATE_MUTATION from './mutations/impersonate_stop.graphql';
import LOGOUT_MUTATION from './mutations/logout.graphql';
import RESET_MUTATION from './mutations/reset.graphql';
import CHANGE_EMAIL_MUTATION from './mutations/change_email.graphql';
import CHANGE_PASSWORD_MUTATION from './mutations/change_password.graphql';
import RESET_PASSWORD_MUTATION from './mutations/reset_password.graphql';
import { parseMutationResult } from '../utils/vuelidate.js';
import { deepEqual } from '../utils/objects.js';

const sportFilter = (profiles, sport) => ((sport)
  ? profiles?.filter((p) => p.sport === sport || p.sport === null)
  : profiles);

const clubFilter = (profiles, club) => ((club)
  ? profiles?.filter((p) => !club || p.club.id === club.id)
  : profiles);

class Session extends EventEmitter {
  constructor(client, permissionError, resetClient) {
    super();
    this._client = client;
    this._resetClient = resetClient;
    this._unsubscribeHandle = null;
    this._ssr = import.meta.env.SSR;
    this.permissionError = permissionError;
    this.permissionDenied = ref(permissionError.value);
    this.user = ref(undefined);
    this.flags = ref(undefined);
    this.notices = ref(undefined);
    // noinspection JSUnusedGlobalSymbols
    this.sessionInitialised = computed(
      () => this.user.value !== undefined && this.flags.value !== undefined,
    );

    watch(permissionError, async (nv, ov) => {
      if (nv && !ov) {
        const login = await this.fetchUser();
        if (!login) {
          this.permissionDenied.value = true;
        }
      } else {
        this.permissionDenied.value = false;
      }
    });
  }

  // noinspection JSUnusedGlobalSymbols
  install(app, injectKey) {
    app.provide(injectKey || 'session', this);
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$session = this;

    // noinspection JSIgnoredPromiseFromCall
    this.fetchFlags();
    // noinspection JSIgnoredPromiseFromCall
    this.fetchNotices();
    if (!this._ssr) {
      // noinspection JSIgnoredPromiseFromCall
      this.fetchUser();
    } else {
      this.user.value = null;
      this.permissionError.value = false;
    }
  }

  _unsubscribe() {
    if (this._unsubscribeHandle) {
      this._unsubscribeHandle();
    }
    this._unsubscribeHandle = null;
  }

  fetchUser() {
    this._unsubscribe();
    return new Promise((resolve) => {
      const { unsubscribe } = pipe(
        this._client.value.query(USER_QUERY, {}, { requestPolicy: 'network-only' }),
        subscribe((result) => {
          // These updates are from urql's cache so: yes please.
          const login = result.data?.user?.id !== this.user.value?.id;
          if (!deepEqual(this.user.value, result.data?.user)) {
            this.user.value = result.data?.user;
          }
          if (login) {
            this.permissionError.value = false;
            this._resetClient();
            Sentry.setUser({ id: this.user.value?.id, email: this.user.value?.email });
            this.emit('login', this.user.value);
          }
          resolve(login);
        }),
      );
      this._unsubscribeHandle = unsubscribe;
    });
  }

  async fetchFlags() {
    const flagsQuery = await this._client.value.query(
      FLAGS_QUERY,
      {},
      { requestPolicy: 'network-only' },
    ).toPromise();
    const flags = {};
    flagsQuery.data?.flags.forEach((f) => {
      flags[f] = true;
    });

    this.flags.value = flags;
  }

  async fetchNotices() {
    const noticesQuery = await this._client.value.query(
      NOTICES_QUERY,
      {},
      { requestPolicy: 'network-only' },
    ).toPromise();

    this.notices.value = noticesQuery.data?.notices;
  }

  get login() {
    return async (username, password, next) => {
      const response = await this._client.value.mutation(
        LOGIN_MUTATION,
        {
          username, password, next,
        },
      ).toPromise();

      const { login } = parseMutationResult(response);
      if (login.__typename === 'User') {
        await this.fetchUser();
        return next;
      }

      return null;
    };
  }

  get impersonate() {
    return async (user) => {
      if (!this.canManageLicenses()) {
        return;
      }

      const response = await this._client.value.mutation(
        IMPERSONATE_MUTATION,
        {
          email: user.email,
        },
      ).toPromise();

      const { userImpersonate } = parseMutationResult(response);
      if (userImpersonate.__typename === 'User') {
        await this.fetchUser();
      }
    };
  }

  get logout() {
    return async () => {
      const response = await this._client.value.mutation(
        LOGOUT_MUTATION,
      ).toPromise();

      if (response.data.logout) {
        this._unsubscribe();
        this.user.value = null;
        this._resetClient();
        Sentry.setUser(null);
        this.emit('login', null);
        return;
      }
      throw new Error();
    };
  }

  get stopImpersonate() {
    return async () => {
      const response = await this._client.value.mutation(
        STOP_IMPERSONATE_MUTATION,
      ).toPromise();

      const { userStopImpersonate } = parseMutationResult(response);
      if (userStopImpersonate.__typename === 'User') {
        await this.fetchUser();
      }
    };
  }

  get requestPasswordReset() {
    return async (email) => {
      const response = await this._client.value.mutation(
        RESET_MUTATION,
        { email: unref(email) },
      ).toPromise();

      if (!response.data.userPasswordRequestReset) {
        return;
      }
      throw new Error(response.data.userPasswordRequestReset.messages);
    };
  }

  get resetPassword() {
    return async (uid, token, password) => {
      const response = await this._client.value.mutation(
        RESET_PASSWORD_MUTATION,
        {
          uid: unref(uid),
          token: unref(token),
          newPassword1: unref(password),
          newPassword2: unref(password),
        },
      ).toPromise();

      if (response.data.userPasswordReset?.__typename === 'User') {
        return;
      }

      parseMutationResult(response);
    };
  }

  get changeEmail() {
    return async (email) => {
      const response = await this._client.value.mutation(
        CHANGE_EMAIL_MUTATION,
        { email: unref(email) },
      ).toPromise();

      parseMutationResult(response);
    };
  }

  get changePassword() {
    return async (oldPassword, newPassword) => {
      const response = await this._client.value.mutation(
        CHANGE_PASSWORD_MUTATION,
        {
          newPassword: unref(newPassword),
          oldPassword: unref(oldPassword),
        },
      ).toPromise();

      parseMutationResult(response);
    };
  }

  hasPermission(permissionRegex, strict = false) {
    const matcher = new RegExp(permissionRegex);
    return (strict === false && this.isSuperUser === true)
      || (!!this.user.value && !!this.user.value.permissions.find((p) => matcher.test(p)));
  }

  get isStaff() {
    return !!this.user.value?.isStaff;
  }

  get isSuperUser() {
    return this.hasPermission('admin:admin', true);
  }

  get isAuthenticated() {
    return !!this.sessionInitialised.value && !!this.user.value;
  }

  get availableSports() {
    const allSports = (this.isStaff
      || this.user.value?.sportProfiles.some((sp) => sp.sport === null)
      || this.user.value?.clubProfiles.some((cp) => cp.sport === null));
    return (allSports) ? null : [...new Set(this.user.value?.sportProfiles.map((s) => s.sport))];
  }

  get isLicenseAdministrator() {
    return (sport) => sportFilter(this.user.value?.sportProfiles.filter(
      (sp) => sp.isLicenseAdministrator,
    ), sport)?.length > 0;
  }

  get isCalendarAdministrator() {
    return (sport) => sportFilter(this.user.value?.sportProfiles.filter(
      (sp) => sp.isCalendarAdministrator,
    ), sport)?.length > 0;
  }

  get isEventAdministrator() {
    return (sport) => sportFilter(this.user.value?.sportProfiles.filter(
      (sp) => sp.isEventAdministrator,
    ), sport)?.length > 0;
  }

  get isMeasurementsAdministrator() {
    return (sport) => sportFilter(this.user.value?.sportProfiles.filter(
      (sp) => sp.isMeasurementsAdministrator,
    ), sport)?.length > 0;
  }

  get isFinancialAdministrator() {
    return (sport) => sportFilter(this.user.value?.sportProfiles.filter(
      (sp) => sp.isFinancialAdministrator,
    ), sport)?.length > 0;
  }

  get isClubEventAdministrator() {
    return (club, sport) => clubFilter(sportFilter(this.user.value?.clubProfiles.filter(
      (cp) => cp.isEventAdministrator,
    ), sport), club)?.length > 0;
  }

  get isClubMemberAdministrator() {
    return (club, sport) => clubFilter(sportFilter(this.user.value?.clubProfiles.filter(
      (cp) => cp.isMemberAdministrator,
    ), sport), club)?.length > 0;
  }

  get isClubTeamAdministrator() {
    return (club, sport) => clubFilter(sportFilter(this.user.value?.clubProfiles.filter(
      (cp) => cp.isTeamAdministrator,
    ), sport), club)?.length > 0;
  }

  get isClubAdministrator() {
    return (club, sport) => sportFilter(this.user.value?.clubProfiles.filter(
      (cp) => cp.club.id === club.id,
    ), sport)?.length > 0;
  }

  // General "can we manage events"
  get canManageEvents() {
    return (sport) => this.isSuperUser
      || this.isStaff
      || this.isEventAdministrator(sport);
  }

  get canManageLicenses() {
    return (sport) => this.isSuperUser
      || this.isStaff
      || this.isLicenseAdministrator(sport);
  }

  get canManageFinance() {
    return (sport) => this.isSuperUser
      || this.isStaff
      || this.isFinancialAdministrator(sport);
  }

  get canManageCalendar() {
    return (sport) => this.isSuperUser
      || this.isStaff
      || this.isCalendarAdministrator(sport);
  }

  // General "can we manage clubs"
  get canManageClubs() {
    return this.isSuperUser
      || this.isStaff
      || this.canManageLicenses();
  }

  get canManageEvent() {
    return (event) => {
      const eventSports = event.shows.map((s) => s.sport).filter((s) => !!s);
      const sports = (eventSports.length > 0) ? eventSports : [null];
      return !!this.user.value && (
        sports.some((s) => this.canManageEvents(s))
        || sports.some((s) => this.isClubEventAdministrator(event.organiser, s))
      );
    };
  }

  get canManageShow() {
    return (show, organiser) => !!this.user.value && (
      this.canManageEvents(show.sport)
        || this.isClubEventAdministrator(organiser, show.sport)
    );
  }

  get canManageClub() {
    return (club) => !!this.user.value && (
      this.canManageClubs
        || this.isClubAdministrator(club)
        || this.hasPermission(`club:${club.id}:.*`)
    );
  }

  get canManageClubTeams() {
    return (club, sport) => !!this.user.value && (
      this.canManageClubs
      || this.isClubTeamAdministrator(club, sport)
      || (club && this.hasPermission(`club:${club.id}:.*`))
    );
  }

  get canManageTeam() {
    return (team) => !!this.user.value
      && this.user.value.handlerProfiles.some((hp) => hp.person.id === team.captain.id);
  }
}

export function createSession(client, permissionError, resetClient) {
  return new Session(client, permissionError, resetClient);
}

export default function useSession() {
  return inject('session');
}
